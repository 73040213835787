<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import AboutProductLabel from '~/components/modal-windows/AboutProductLabel.vue'
import ProductLabelEnum from '~/enums/product-label'
import type { ProductLabel } from '~/types/products'

const props = withDefaults(
  defineProps<{
    isProductCard?: boolean
    isProductPage?: boolean
    productData: any
  }>(),
  { isProductCard: false, isProductPage: false }
)

const productLabels = computed(() => {
  const { meta } = props.productData
  let labels: ProductLabel[] = Array.isArray(meta.labels) ? [...meta.labels] : []

  if (props.isProductCard) {
    labels = labels.filter((x: any): boolean => !hasDescription(x.title))
  }

  return labels

  // return labels.length ? [labels[0]] : []
})

function hasDescription (productLabel: ProductLabelEnum): boolean {
  return ProductLabelEnum.hasDescription(productLabel)
}

async function onProductLabelClick (productLabel: ProductLabelEnum): Promise<void> {
  if (!ProductLabelEnum.hasDescription(productLabel)) {
    return
  }

  await useModal({ component: AboutProductLabel, attrs: { productLabel } }).open()
}
</script>

<template>
  <div v-if="productLabels.length" class="product-labels" :class="{ 'product-labels_is-product-page': isProductPage }">
    <div
      v-for="{ backgroundColor, color, title } in productLabels"
      :key="title"
      class="product-labels__item"
      :class="{ 'product-labels__item_has-description': hasDescription(title as ProductLabelEnum) }"
      :style="{ backgroundColor, color }"
      @click.prevent="onProductLabelClick(title as ProductLabelEnum)"
    >
      {{ title }}
      <SvgIcon
        v-if="hasDescription(title as ProductLabelEnum)"
        class="product-labels__icon"
        name="outlined/navigate-next"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-labels {
  align-items: flex-start;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  left: 16px;
  min-height: 1px; // To fix the blur of the discount label.
  min-width: 1px; // To fix the blur of the discount label.
  position: absolute;
  z-index: 2;

  @media screen and (max-width: 480px) {
    bottom: 8px;
    left: 8px;
  }

  &_is-product-page {
    flex-direction: row;
    left: 0;
    margin-left: 0 !important;
    position: relative;
    top: 0;
    z-index: auto;

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  &_is-product-page &__item {
    margin-left: 8px;

    @media screen and (max-width: 480px) {
      margin-left: 0;
    }
  }

  &__icon {
    font-size: 16px;
    margin: -2px -4px -2px 0;
  }

  &__item {
    align-items: center;
    border-radius: 4px;
    display: flex;
    font-size: 14px;
    justify-content: center;
    line-height: 16px;
    padding: 4px 8px;
    user-select: none;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      line-height: 14px;
      padding: 2px 4px;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &_has-description {
      cursor: pointer;
    }
  }
}
</style>
