<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import QuantityCounter from '~/components/form-elements/QuantityCounter.vue'
import AddProductToList from '~/components/modal-windows/AddProductToList.vue'
import BackInStockNotification from '~/components/modal-windows/BackInStockNotification.vue'
import DiscountLabel from '~/components/products/DiscountLabel.vue'
import ProductLabels from '~/components/products/ProductLabels.vue'
import Rating from '~/components/products/Rating.vue'
import { useProduct } from '~/composables/product'
import ImageType from '~/enums/image-type'
import helpers from '~/helpers'
import { useAuthStore } from '~/store/auth'
import { useProductsStore } from '~/store/products'

const props = withDefaults(
  defineProps<{
    hasBorder?: boolean
    listId?: string | null
    productData: any
  }>(),
  { hasBorder: false, listId: null }
)

const emit = defineEmits<{
  (e: 'removeProductFromList', productId: string): void
}>()

const imageTypes = [ImageType.Thumbnail240p, ImageType.Thumbnail360p, ImageType.Thumbnail480p, ImageType.Original]
const messages = {
  failedToDeleteProductFromList: 'Не удалось удалить товар из списка.',
  productIsDeletedFromList: 'Товар удалён из списка.'
}

const { $apiHelper, $toast } = useNuxtApp()
const authStore = useAuthStore()
const { addProductToCart, toggleIsInWishList, setCartProductQuantity } = useProduct()
const productsStore = useProductsStore()

const canBeOrdered = computed(() => !isOutOfStock.value || isOnPreOrder.value)
const imageUrl = computed(() => {
  const imageUrls = helpers.getImageUrls(props.productData.relationships.images.data)

  return imageUrls ? helpers.getImageUrl(imageUrls, imageTypes) : null
})
const isHalal = computed(() => props.productData.meta.isHalal)
const isInWishList = computed(() => props.productData.meta.isInWishList)
const isOnPreOrder = computed(() => props.productData.meta.isOnPreOrder)
const isOutOfStock = computed(() => props.productData.meta.isOutOfStock)
const multiplicity = computed(() => props.productData.attributes.multiplicity ?? 1)
const name = computed(() => props.productData.attributes.name)
const productId = computed(() => props.productData.id)
const quantity = computed({
  get (): number {
    return productsStore.getCartProductQuantity(productId.value) ?? 0
  },
  set (quantity: number): void {
    setCartProductQuantity(productId.value, quantity)
  }
})
const ratingValue = computed(() => {
  const { rating } = props.productData.attributes

  return typeof rating === 'number' ? rating : null
})
const url = computed(() => helpers.getProductPath(props.productData))

async function deleteListProduct (): Promise<void> {
  const { failedToDeleteProductFromList, productIsDeletedFromList } = messages

  try {
    await $apiHelper.lists.deleteListProduct(props.listId!, productId.value)

    emit('removeProductFromList', productId.value)
    $toast.success(productIsDeletedFromList)
  } catch (error) {
    console.error(error)

    $toast.error(failedToDeleteProductFromList)
  }
}

async function openAddProductToListModalWindow (): Promise<void> {
  await useModal({ component: AddProductToList, attrs: { productId: productId.value } }).open()
}

async function openBackInStockNotificationModalWindow (): Promise<void> {
  await useModal({ component: BackInStockNotification, attrs: { productId: productId.value } }).open()
}
</script>

<template>
  <div class="product-card" :class="{ 'has-border': hasBorder }">
    <div class="product-card__top">
      <NuxtLink class="product-card__image" :to="url">
        <div class="product-card__image-inner">
          <img
            v-if="imageUrl"
            v-lazy="imageUrl"
            :alt="name"
            :class="{ 'black-and-white': helpers.isProductImageBlackAndWhite(productData) }"
            loading="lazy"
          >

          <DiscountLabel v-if="canBeOrdered" :product-data="productData" />

          <ProductLabels is-product-card :product-data="productData" />
        </div>
      </NuxtLink>

      <div class="product-card__rating-and-name">
        <Rating class="product-card__rating" :rating="ratingValue" />

        <NuxtLink class="product-card__name" :to="url">
          {{ name }}
        </NuxtLink>
      </div>

      <div class="product-card__top-right">
        <BasicButton
          :aria-label="isInWishList ? 'Удалить из\xA0«Избранного»' : 'Добавить в\xA0«Избранное»'"
          :color="isInWishList ? 'red' : 'light-gray'"
          :icon="isInWishList ? 'outlined/favorite' : 'outlined/favorite-border'"
          is-icon-only
          is-red-on-hover
          is-small
          @click="toggleIsInWishList(productData)"
        />

        <DropdownMenu v-if="listId !== null" button-color="light-gray" icon-name="outlined/menu" is-on-product-card>
          <DropdownMenuItem class="nowrap" @click="openAddProductToListModalWindow">
            <SvgIcon name="outlined/playlist-add" />
            В другой список
          </DropdownMenuItem>
          <DropdownMenuItem class="nowrap" @click="deleteListProduct">
            <SvgIcon name="outlined/delete" />
            Удалить из списка
          </DropdownMenuItem>
        </DropdownMenu>
        <BasicButton
          v-else-if="authStore.isUser"
          :aria-label="'Добавить в\xA0список'"
          color="light-gray"
          icon="outlined/playlist-add"
          is-green-on-hover
          is-icon-only
          is-small
          @click="openAddProductToListModalWindow"
        />

        <SvgIcon v-if="isHalal" class="product-card__halal-icon" name="labels/halal" />
      </div>
    </div>
    <div class="product-card__bottom">
      <PriceWithPromoCode mode="product-card" :product-data="productData" />

      <QuantityCounter
        v-if="quantity"
        v-model="quantity"
        class="product-card__quantity-counter mobile"
        is-small
        :min="0"
        :multiplicity="multiplicity"
      />

      <QuantityCounter
        v-if="quantity"
        v-model="quantity"
        class="product-card__quantity-counter desktop"
        :min="0"
        :multiplicity="multiplicity"
      />
      <template v-else>
        <BasicButton
          class="desktop"
          :class="canBeOrdered ? 'product-card__add-to-cart-button' : 'product-card__get-notified-button'"
          :color="canBeOrdered ? 'green' : 'body-background'"
          is-small
          :title="canBeOrdered ? 'В\xA0корзину' : 'Уведомить'"
          @click="canBeOrdered ? addProductToCart(productId, multiplicity) : openBackInStockNotificationModalWindow()"
        />

        <BasicButton
          :aria-label="canBeOrdered ? 'В\xA0корзину' : 'Уведомить о\xA0поступлении'"
          class="mobile"
          :class="canBeOrdered ? 'product-card__add-to-cart-button' : 'product-card__get-notified-button'"
          color="green"
          :icon="canBeOrdered ? 'outlined/add-shopping-cart' : 'outlined/add-alert'"
          is-icon-only
          is-small
          @click="canBeOrdered ? addProductToCart(productId, multiplicity) : openBackInStockNotificationModalWindow()"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.product-card {
  $mobile-width: 480px;

  background: white;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &.has-border {
    border: 1px solid $main-light-gray-color;
  }

  &__add-to-cart-button,
  &__get-notified-button {
    &.desktop {
      @media screen and (max-width: $mobile-width) {
        display: none;
      }
    }

    &.mobile {
      display: none;

      @media screen and (max-width: $mobile-width) {
        display: block;
      }
    }
  }

  &__bottom {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px;

    @media screen and (max-width: $mobile-width) {
      padding: 8px;
    }
  }

  &__halal-icon {
    color: $main-green-color;
    font-size: 42px;
    margin-top: 4px;
    pointer-events: none !important;

    @media screen and (max-width: $mobile-width) {
      font-size: 24px;
      margin-right: 4px;
    }
  }

  &__image & {
    border: none;
    display: block;
    position: relative;

    &__image-inner {
      height: 0;
      padding-bottom: 56.25%;
      position: relative;

      img {
        height: 100%;
        min-height: 100%;
        object-fit: contain;
        position: absolute;
        width: 100%;
      }
    }
  }

  &__name {
    color: inherit;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__quantity-counter {
    &.desktop {
      @media screen and (max-width: $mobile-width) {
        display: none;
      }
    }

    &.mobile {
      display: none;

      @media screen and (max-width: $mobile-width) {
        display: flex;
      }
    }
  }

  &__rating {
    margin-bottom: 8px;

    @media screen and (max-width: $mobile-width) {
      margin-bottom: 4px;
    }
  }

  &__rating-and-name {
    padding: 16px 16px 0;

    @media screen and (max-width: $mobile-width) {
      font-size: 12px;
      line-height: 14px;
      padding: 8px 8px 0;
    }
  }

  &__top {
    position: relative;
  }

  &__top-right {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 2;

    @media screen and (max-width: $mobile-width) {
      right: 0;
      top: 0;
    }

    & > :deep(*) {
      pointer-events: auto;
    }
  }
}
</style>
