<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import Checkboxes from '~/components/form-elements/Checkboxes.vue'
import CreateOrEditList from '~/components/modal-windows/CreateOrEditList.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import helpers from '~/helpers'
import type { CheckboxChoice } from '~/types/form-elements'
import type { Page } from '~/types/json-api'

const model = defineModel<boolean>()

const props = defineProps<{
  productId?: string,
  productIds?: string[]
}>()

const messages = {
  failedToAddProductToList: 'Не удалось добавить товар в список.',
  failedToDeleteProductFromList: 'Не удалось удалить товар из списка.',
  failedToGetLists: 'Не удалось получить списки.',
  productIsAddedToList: 'Товар добавлен в список.',
  productIsDeletedFromList: 'Товар удалён из списка.'
}

const { $apiHelper, $toast } = useNuxtApp()

const isAddingOrDeleting = ref(false)
const listIds = ref<string[]>([])

// Lists
const isListsDataLoading = ref(false)
const isListsDataLoadingError = ref(false)
const listsData = ref<any[]>([])
const page = ref<Page>({ number: 1, size: 20 })
const totalLists = ref<number | null>(null)
const totalPages = ref<number | null>(null)

const listChoices = computed(
  () => listsData.value.map((x: any): CheckboxChoice => ({ labelText: x.attributes.name, value: x.id }))
)

async function addOrDeleteListProduct (listId: string, isAdding: boolean): Promise<void> {
  isAddingOrDeleting.value = true

  const { productId, productIds } = props
  const {
    failedToAddProductToList,
    failedToDeleteProductFromList,
    productIsAddedToList,
    productIsDeletedFromList
  } = messages

  try {
    if (isAdding) {
      await $apiHelper.lists.createListProduct(listId, productId, productIds)
    } else {
      await $apiHelper.lists.deleteListProduct(listId, productId)
    }

    $toast.success(isAdding ? productIsAddedToList : productIsDeletedFromList)
  } catch (error) {
    console.error(error)

    $toast.error(isAdding ? failedToAddProductToList : failedToDeleteProductFromList)

    // Rollback.
    if (isAdding) {
      const listIdIndex = listIds.value.indexOf(listId)

      if (listIdIndex !== -1) {
        listIds.value.splice(listIdIndex, 1)
      }
    } else {
      listIds.value.push(listId)
    }
  }

  isAddingOrDeleting.value = false
}

async function beforeOpen (): Promise<void> {
  await loadListsData()
}

async function changePage (pageNumber: boolean): Promise<void> {
  page.value.number = pageNumber

  await loadListsData()
}

function close (): void {
  model.value = false
}

function closed (): void {
  listIds.value = []
  page.value.number = 1
}

async function loadListsData (): Promise<void> {
  isListsDataLoading.value = true
  isListsDataLoadingError.value = false
  listsData.value = []
  totalPages.value = null

  const { number, size } = page.value
  const params = helpers.getParams([
    { name: 'page[number]', value: number, condition: number !== null },
    { name: 'page[size]', value: size, condition: size !== null },
    { name: props.productId ? 'productId' : 'productIds', value: props.productId || props.productIds, condition: true }
  ])

  try {
    const { data, meta } = await $apiHelper.lists.getLists(params) as any

    listsData.value = data
    listIds.value = data.filter((x: any): boolean => x.meta.hasProduct).map((x: any): string => x.id)
    totalLists.value = meta.totalLists
    totalPages.value = helpers.getTotalPages(meta.totalLists, size)
  } catch (error) {
    console.error(error)

    isListsDataLoadingError.value = true

    $toast.error(messages.failedToGetLists)
  }

  isListsDataLoading.value = false
}

async function onCheckboxChanged (event: any): Promise<void> {
  const { checked, value } = event.target

  await addOrDeleteListProduct(value, checked)
}

async function openCreateOrEditListModalWindow (): Promise<void> {
  await useModal({ component: CreateOrEditList, attrs: { onLoadData: loadListsData } }).open()
}

defineExpose({ addOrDeleteListProduct })
</script>

<template>
  <CustomModalWindow v-model="model" @before-open="beforeOpen" @closed="closed">
    <template #title>
      Добавление товара в&nbsp;список
    </template>

    <Preloader v-if="isListsDataLoading" />
    <Notification v-else-if="isListsDataLoadingError" status="error">
      {{ messages.failedToGetLists }}
    </Notification>
    <template v-else>
      <Notification class="mb24" is-small status="info">
        <template v-if="listsData.length">
          Выберите список или создайте новый.
        </template>
        <template v-else>
          У&nbsp;вас нет списков.
        </template>
      </Notification>
      <BasicButton class="mb24" color="green" title="Создать новый список" @click="openCreateOrEditListModalWindow" />

      <Checkboxes
        v-if="listChoices.length"
        v-model="listIds"
        :choices="listChoices"
        is-vertical
        label-text="Списки"
        name="listIds"
        @on-checkbox-changed="onCheckboxChanged"
      />
      <Pagination class="mt24" :current-page="page.number" :last-page="totalPages" @change-page="changePage" />
    </template>

    <template v-if="!isListsDataLoading" #action>
      <a v-if="isListsDataLoadingError" href="#" @click.prevent="loadListsData">Повторить попытку</a>
      <a v-else href="#" @click.prevent="close">Готово</a>
    </template>
  </CustomModalWindow>
</template>
