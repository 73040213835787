<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    isProductPage?: boolean
    productData: any
  }>(),
  { isProductPage: false }
)

const percentageDiscount = computed(() => {
  const { oldPrice, price } = props.productData.attributes

  if (oldPrice === null || price === null) {
    return null
  }

  return Math.floor(100 * (1 - price / oldPrice))
})
</script>

<template>
  <div v-if="percentageDiscount" class="discount-label" :class="{ 'is-product-page': isProductPage }">
    {{ percentageDiscount > 0 ? '−' : '+' }}{{ Math.abs(percentageDiscount) }}%
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.discount-label {
  align-items: center;
  background: $main-red-color;
  border-radius: 4px;
  color: white;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  left: 16px;
  line-height: 16px;
  padding: 4px;
  position: absolute;
  top: 16px;
  z-index: 2;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    left: 8px;
    line-height: 14px;
    padding: 2px 4px;
    top: 8px;
  }

  &.is-product-page {
    height: fit-content;
    left: 0;
    position: relative;
    top: 0;
    z-index: auto;
    margin-bottom: 8px;
    width: fit-content;
  }
}
</style>
